import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route } from 'react-router-dom';
import { connect } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import urls from "../../../../Utils//URLs";
import Can from "../../../Components/Can";
import PageNotFound from "../../../PageNotFound";
import StoreOverviewPage from "./Scenes/StoreOverviewPage";
import StoreOverviewLayout from "./Components/StoreOverviewLayout/StoreOverviewLayout";
import StoreListService from "../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";
function StoreOverviewMainPage(props) {

  return (
    <>
      {(props.adProfiles.length > 0)?
      <StoreOverviewLayout>
      <Can
                role={props.accessLevel}
                perform={"home:simple"}
                yes={() => (
                    <Switch>
                    <Route exact path={`${urls.STORE_OVERVIEW_MY_STORES_URL}/:storeIntegerID?`} component={StoreOverviewPage} />
                    <Route component={PageNotFound} />
                    </Switch>
                )}
                no={() => (
                    <Switch>
                        {/* <Route exact path={`${urls.STORE_OVERVIEW_MY_STORES_URL}/:storeIntegerID?`} component={StoreOverviewPage} /> */}
                        <Route component={PageNotFound} />
                    </Switch>
                )}
            />
       
      </StoreOverviewLayout>
      :  <p>Waiting for the condition to be true...</p>}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    adProfiles: state.accountOverview.landing.adProfiles,
   
  };
};

export default connect(mapStateToProps)(StoreOverviewMainPage);
