import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../RegistrationJourney/Components/ProgressBar.css";
import GetAllTicketsDataService from "../../../../Service/DashboardServices/TroubleTicketServices/GetAllTicketsDataService";
import UpdateTicketStatusService from "../../../../Service/DashboardServices/TroubleTicketServices/UpdateTicketStatusService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faArrowLeft,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../Components/Buttons/LoadingButton";
import CreateTicket from "./Components/CreateTicket";
import ViewTicket from "./Components/ViewTicket";
import CustomModal from "../../../Components/Modals/CustomModal";
import backButton from "../../../../designUtils/Logo/BackButton.svg";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { SetInnerHtmlWithClassReplacement } from "../../../../Utils/Constants";
function TicketListPage(props) {
  // const [mainCategories, setMainCategories] = useState();
  const [mainRequestTypes, setMainRequestTypes] = useState();
  const [mainRequestTypeTitle, setMainRequestTypeTitle] = useState("");
  const [pathwayTitle, setPathwayTitle] = useState("");
  const [subTypeTitle, setSubTypeTitle] = useState("");
  const [problemAreaList, setProblemAreaList] = useState();
  const [problemAreaPathwayList, setProblemAreaPathwayList] = useState();
  const [subRequestTypes, setSubRequestTypes] = useState();
  const [ticketList, setTicketList] = useState([]);
  const [ticketViewData, setTicketViewData] = useState();
  const [ticketId, setTicketId] = useState();
  const [ticketStatuslist, setTicketStatuslist] = useState();
  const [issueDescriptionText, setIssueDescriptionText] = useState("");
  const [createDate, setCreateDate] = useState("");
  // storeOverview

  const [storeData, setStoreData] = useState(props.storeOverview);
  const [storeProductData, setStoreProductData] = useState(props.storeProducts);

  console.log(props.storeProducts);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [isCreateTicketOpen, setIsCreateTicketOpen] = useState(false);
  const [isEditTicketOpen, setIsEditTicketOpen] = useState(false);
  const [isViewTicketOpen, setIsViewTicketOpen] = useState(false);
  const [isTicketReOpen, setIsTicketReOpen] = useState(false);

  const [query, setQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("Open");
  const [issueDescription, setIssueDescription] = useState(false);
  // useEffect(() => {
  // if (props.requestSubTypeID && props.requestTypeID) {
  //   handleCreateTicket();
  // }
  // }, [1]);
  const filteredTicketList = ticketList.filter((row) => {
    const issueDescription = (row.issueDescription || "").toLowerCase();
    const requestSubTypes = row.requestSubTypes.toLowerCase();
    const ticketStatus = row.ticketStatus.toLowerCase();
    const problemAreaPathway = row.problemAreaPathway.toLowerCase();
    const problemArea = row.problemArea.toLowerCase();
    const priority = row.priority.toLowerCase();
    const ticketId = row.ticketId.toString();
    if (query !== "") {
      return (
        issueDescription.includes(query.toLowerCase()) ||
        requestSubTypes.includes(query.toLowerCase()) ||
        requestSubTypes.includes(query.toLowerCase()) ||
        ticketStatus.includes(query.toLowerCase()) ||
        problemAreaPathway.includes(query.toLowerCase()) ||
        problemArea.includes(query.toLowerCase()) ||
        priority.includes(query.toLowerCase()) ||
        ticketId.includes(query.toLowerCase())
      );
    } else if (statusFilter === "All") {
      return true; // Show all tickets when "All" is selected
    } else if (statusFilter) {
      if (statusFilter == "Open") {
        return row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved";
      } else {
        return row.ticketStatus === statusFilter;
      }
    } else {
      return true; // Show all tickets when "All" is selected
    }
  });

  useEffect(() => {
    console.log("ticket");
    console.log(props);
    const fetchFilteredTickets = async () => {
      let data = {
        integerId: props.storeIntegerId
          ? props.storeIntegerId
          : props.storeOverview.integerID,
        problemAreaId: props.problemAreaId,
        mainRequestTypeId: props.requestTypeID,
        dateRange: props.dateRange ? props.dateRange : "",
      };
      const response = await GetAllTicketsDataService(props.token, data);
      if (response) {
        console.log("GetAllTicketsDataService back");
        console.log(response);
        // setCreateDate(response.ticketList[0].ticketCreatedAt)
        setTicketList(response.ticketList);
        setMainRequestTypes(response.mainRequestTypes);
        response.mainRequestTypes.map((type) => {
          if (props.requestTypeID == type.id) {
            setMainRequestTypeTitle(type.requestType);
          }
        });
        setProblemAreaList(response.problemAreaList);
        setProblemAreaPathwayList(response.problemAreaPathwayList);
        setSubRequestTypes(response.subRequestTypes);
        setTicketViewData(response.ticketHistory);
        setTicketStatuslist(response.ticketStatuslist);
        if (props.requestSubTypeID && props.requestTypeID) {
          handleCreateTicket();
        }
      }
    };

    fetchFilteredTickets();
  }, []);

  const handleCreateTicket = () => {
    setIsCreateTicketOpen(true);
  };
  const handleCreateAndViewTicketClose = (response,flag) => {
    console.log(response)
    const filteredTickets = response.ticketList.filter(ticket => (ticket.integerId === (props.storeIntegerId? props.storeIntegerId : props.storeOverview.integerID)) && ticket.isInternalTicket === false);
    setTicketList(filteredTickets);
    console.log(filteredTickets)
    // setTicketList(response.ticketList);
    setMainRequestTypes(response.mainRequestTypes);
    setSubRequestTypes(response.subRequestTypes);
    // setTicketViewData(response.ticketHistory);
    setTicketStatuslist(response.ticketStatuslist);
    if(flag==1)
    {
      setIsCreateTicketOpen(false);
    }
    setIsViewTicketOpen(false);
  };
  const handleEditTicket = () => {
    setIsEditTicketOpen(true);
  };
  const handleViewTicket = (ticketId, mainType, pathway, subType) => {
    console.log(ticketId);
    setTicketId(ticketId);
    setMainRequestTypeTitle(mainType);
    setPathwayTitle(pathway);
    setSubTypeTitle(subType);
    setIsViewTicketOpen(true);
  };
  const viewIssueDescription = (text) => {
    setIssueDescription(true);
    setIssueDescriptionText(text);
  };
  const handleReopenTicket = () => {
    setIsTicketReOpen(true);
  };
  const updateTicketStatus = async () => {
    setLoadingUpdate(true);
    await UpdateTicketStatusService(props.token).then((response) => {
      if (response) {
        console.log(response);
      }
    });
    setLoadingUpdate(false);
  };
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const columns = [
    {
      Header: "ID",
      accessor: "ticketId",
      width: 80,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          {/* <a
            className="m-0"
            href="#"
            onClick={() => handleViewTicket(cellInfo.original.ticketId,cellInfo.original.requestType)}
          >
            {cellInfo.value}
          </a> */}
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    // {
    //   Header: "Priority",
    //   accessor: "priority",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p>{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    {
      Header: "Date",
      accessor: "ticketOriginaldate",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
         <p className="m-0">{cellInfo.original.ticketCreatedAt}</p>
        </div>
      ),
    },
    // {
    //   Header: "Subject",
    //   accessor: "ticketTitle",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex  w-100">
    //       <a
    //         className="m-0"
    //         href="#"
    //         onClick={() => handleViewTicket(cellInfo.original.ticketId,cellInfo.original.requestType)}
    //       >
    //         {cellInfo.value}
    //       </a>
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Request Type",
    //   accessor: "requestType",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    {
      Header: " Problem Area",
      accessor: "problemArea",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Pathway",
      accessor: "problemAreaPathway",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Sub Request ",
      accessor: "requestSubTypes",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Issue Description ",
      accessor: "issueDescription",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() =>
              viewIssueDescription(
                cellInfo.value,
                cellInfo.original.issueDescription
              )
            }
          >
            {stripHtmlTags(cellInfo.value)}
          </p>
        </div>
      ),
    },
    // {
    //     Header: "Attachment",
    //     Cell: (cellInfo) => (
    //       <div className="d-flex justify-content-center w-100">
    //         <Button className="btn btn-success ml-1 mr-1" onClick={handleViewTicket} title="View">
    //           <FontAwesomeIcon icon={faEye} className={""} />
    //         </Button>
    //       </div>
    //     ),
    //   },
    {
      Header: "Status",
      accessor: "ticketStatus",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span
            className={
              cellInfo.value === "Closed"
                ? "text-primary "
                : cellInfo.value === "New"
                ? "text-danger  "
                : cellInfo.value === "Informed"
                ? "text-secondary "
                : cellInfo.value === "Resolved"
                ? "text-success  "
                : cellInfo.value === "Need info"
                ? "text-info "
                : cellInfo.value === "Assigned"
                ? "text-warning"
                : cellInfo.value === "Ticket updated"
                ? "text-warning"
                : "btn btn-sm"
            }
          >
            <a
              className="m-0"
              href="#"
              onClick={() =>
                {handleViewTicket(
                  cellInfo.original.ticketId,
                  cellInfo.original.requestType,
                  cellInfo.original.problemAreaPathway,
                  cellInfo.original.requestSubTypes
                );setCreateDate(cellInfo.original.ticketCreatedAt)}
              }
            >
              {cellInfo.value}
            </a>
          </span>
        </div>
      ),
    },
    // {
    //   Header: "Actions",
    //   accessor: "ticketStatus",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">

    //       {cellInfo.value === "Closed" ? (
    //         <Button
    //           className="btn -success ml-1 mr-1 btn-sm"
    //           onClick={handleReopenTicket}
    //           title="Reopen"
    //         >
    //           <FontAwesomeIcon icon={faDoorOpen} className={""} />
    //         </Button>
    //       ) : (
    //         ""
    //       )}
    //       {cellInfo.value === "Need info" ? (
    //         // <Button
    //         //   className="btn btn-success ml-1 mr-1 btn-sm"
    //         //   onClick={handleReopenTicket}
    //         //   title="Reply"
    //         // >
    //         //   <FontAwesomeIcon icon={faReply} className={""} />
    //         // </Button>'
    //         ''
    //       ) : (
    //         ""
    //       )}

    //     </div>
    //   ),
    // },
  ];

  const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(issueDescriptionText);

  return (
    <section className="">
      <h5>
        <button
          onClick={
            props.problemAreaName
              ? () => {
                  props.goBackDTI();
                  props.closeSubtype();
                }
              : () => props.goBackPA()
          }
          className="hover-btn-link back-btn"
        >
          <img className="m-2" src={backButton} alt="not foun" />
        </button>
      </h5>
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col md="12" className="mbg-3 p-0">
            <Card className="main-card mb-3">
              <CardBody>
                <Row md="12">
                  <Col md="12">
                    <CardTitle>Ticket List </CardTitle>
                  </Col>
                  <Col md={10}>
                    <label>
                      <input
                        type="text"
                        placeholder="Search Text"
                        value={query}
                        className="search form-control "
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </label>
                  </Col>
                </Row>
                <span className="mb-2" style={{ marginLeft: "0px" }}>
                  <input
                    type="radio"
                    name="statusFilter"
                    value={statusFilter}
                    checked={statusFilter === "All"}
                    className="ml-2"
                    onChange={() => setStatusFilter("All")}
                  />
                  &nbsp;
                  <b>All</b>
                </span>
                <span className="mb-2" style={{ marginLeft: "40px" }}>
                  <input
                    type="radio"
                    name="statusFilter"
                    value={statusFilter}
                    checked={statusFilter === "Open"}
                    className="ml-2"
                    onChange={() => setStatusFilter("Open")}
                  />
                  &nbsp;
                  <b>Open</b>
                </span>
                {ticketStatuslist
                  ? ticketStatuslist.map((status) => (
                      <span className="mb-2" style={{ marginLeft: "30px" }}>
                        <input
                          type="radio"
                          name="statusFilter"
                          value={status.ticketStatus}
                          checked={statusFilter === status.ticketStatus}
                          className="ml-2"
                          onChange={() => setStatusFilter(status.ticketStatus)}
                        />
                        &nbsp;
                        <b>{status.ticketStatus}</b>
                      </span>
                    ))
                  : ""}

                <ReactTable
                  data={filteredTicketList}
                  columns={columns}
                  className="-fixed -highlight -striped mt-2"
                  getTheadProps={() => {
                    return {
                      style: {
                        overflowY: "hidden",
                        background: "#B1FA63",
                        color: "#243837",
                      },
                    };
                  }}
                  defaultPageSize={5}
                  noDataText={"No Data Found."}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <CustomModal
        // header={"Create Ticket: " + storeData.name +" - " + mainRequestTypeTitle}
        header={props.problemAreaPathwayName + " / " + props.requestSubTypeName}
        isOpen={isCreateTicketOpen}
        toggle={() => setIsCreateTicketOpen(!isCreateTicketOpen)}
        size={"xl"}
        className="bgColor"
        // footer={
        //   <>
        //     <Button
        //       color={"link"}
        //       onClick={() => setIsCreateTicketOpen(!isCreateTicketOpen)}
        //     >
        //       Cancel
        //     </Button>
        //   </>
        // }
      >
        <CreateTicket
        setIsCreateTicketOpen={setIsCreateTicketOpen}
          mainRequestTypes={mainRequestTypes}
          subRequestTypes={subRequestTypes}
          requestTypeID={props.requestTypeID}
          requestSubTypeID={props.requestSubTypeID}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          storeProductData={storeProductData}
          problemAreaPathwayList={problemAreaPathwayList}
          problemAreaList={problemAreaList}
          problemAreaId={props.problemAreaId}
          problemAreaPathwayId={props.problemAreaPathwayId}
          asinForTicketList={
            props.asinForTicketList ? props.asinForTicketList : ""
          }
        />
      </CustomModal>
      <CustomModal
        header={ticketId + " / " + pathwayTitle + " / " + subTypeTitle + " / " + createDate}
        isOpen={isViewTicketOpen}
        toggle={() => setIsViewTicketOpen(!isViewTicketOpen)}
        size={"xl"}
        className="bgColor"
      >
        <ViewTicket
          ticketId={ticketId}
          ticketList={ticketList}
          ticketStatuslist={ticketStatuslist}
          mainRequestTypes={mainRequestTypes}
          subRequestTypes={subRequestTypes}
          ticketViewData={ticketViewData}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          problemAreaPathwayList={problemAreaPathwayList}
          problemAreaList={problemAreaList}
          pathwayTitle={pathwayTitle}
          subTypeTitle={subTypeTitle}
          problemAreaId={props.problemAreaId}
          problemAreaPathwayId={props.problemAreaPathwayId}
        />
      </CustomModal>
      <CustomModal
        header="Re-Open Ticket"
        isOpen={isTicketReOpen}
        toggle={() => setIsTicketReOpen(!isTicketReOpen)}
        footer={
          <Fragment>
            <LoadingButton
              loading={props.loading}
              color="success"
              onClick={() => {
                setIsTicketReOpen(!isTicketReOpen);
                updateTicketStatus();
              }}
            >
              Re-Open
            </LoadingButton>
            <Button
              style={{ fontSize: "small" }}
              color="secondary"
              onClick={() => setIsTicketReOpen(!isTicketReOpen)}
            >
              Cancel
            </Button>
          </Fragment>
        }
      >
        <Row>
          <Col md="12">
            <Label for="Comment" className="mt-1">
              Comment:
            </Label>
            <Input
              id="ticketHistoryComment"
              name="ticketHistoryComment"
              type="textarea"
              value={""}
              onChange={""}
            />
          </Col>
        </Row>
      </CustomModal>
      <CustomModal
        header={"Issue Description"}
        isOpen={issueDescription}
        toggle={() => setIssueDescription(!issueDescription)}
        size={"lg"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIssueDescription(!issueDescription)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <p className="issue-discription-set" dangerouslySetInnerHTML={{__html : replaceIssueDescriptionText}}/>
          {/* <p>{issueDescriptionText}</p> */}
        </Label>
      </CustomModal>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(TicketListPage);
