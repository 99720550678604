import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import GetRequestSubTypesService from "../../../../../../Service/DashboardServices/HeplDeskServices/GetRequestSubTypesService";
import CustomModal from "../../../../../Components/Modals/CustomModal";
import TicketListPage from "../../../TroubleTickets/TicketListPage";
import ArrowIcon_icon from "../../../../../../designUtils/Logo/Vector_Arrow.svg"
import urls from "../../../../../../Utils/URLs";
import Arrow_Button from "./../../../../../../assets/img/StoreOverview_Img/Arrow_Button.svg";
import BackButton from "../.././../../../../designUtils/Logo/BackButton.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
function StoreOverviewDoThingsItems(props) {
  const [requestSubTypes, setRequestSubTypes] = useState();
  const [requestSubTypeID, setRequestSubTypeID] = useState();
  const [requestSubTypeName, setRequestSubTypeName] = useState();
  const [doThingItemPage, setDoThingItemPage] = useState(true);
  const [ticketListPage, setTicketListPage] = useState(false);

  useEffect(() => {
    const data = {
      mainRequestTypeId: props.id,
      problemAreaPathwayId: props.problemAreaPathwayId,
    };
    const response = GetRequestSubTypesService(props.token, data).then(
      (response) => {
        if (response) {
          setRequestSubTypes(response);
        }
      }
    );
  }, []);
  
  const rebderDoThingItemPage = () => {
    return (
      <Container>
      <>
     {/*   <button
           onClick={()=>props.goBack()}
            style={{border: "none",background: "none"}}
            className="hover-btn-link"
          >
            Do Things 
          </button>/<button
            onClick={()=>props.goBackPA()}
            style={{border: "none",background: "none"}}
            className="hover-btn-link"
          >
          Solve Problems
          </button>/<button
            onClick={}
            style={{border: "none",background: "none"}}
            className="hover-btn-link"
          > 
          {props.problemAreaName}
          </button> / {props.problemAreaPathwayName}*/}
        <h5 className="mt-1 mb-3">
        <button onClick={()=>{props.goBackPAP();props.closeProblemAreaPathway()}} className="hover-btn-link back-btn"  >
        <img  className='m-2'src={BackButton} alt="not foun"/>
              </button> 
          From the following, what {props.problemAreaPathwayName} related problem are you facing?
        </h5>
        {requestSubTypes
          ? requestSubTypes.map((SubType) => (
              <Col md={12} className='p-0'>
                <a href="#"   className="text-decoration-none " onClick={()=>handleTicketListPage(SubType.id,SubType.requestSubTypes )} >
                <Card className=" p-3 mb-4 pb-0 subtypesOfPoblemPathway ">
                  <h5 className="">
                    <b> {SubType.requestSubTypes}</b>
                      <img style={{ float: "right" }} src={ArrowIcon_icon} />
                  </h5>
                  <p className="m-0 p-0">
                    {SubType.helpText}
                  </p>
                </Card>
                </a>
              </Col>
            ))
          : ""}
      </>
      </Container>
    );
  };
  const goBackDTI = () => {
    setDoThingItemPage(true);
    setTicketListPage(false);
  }
  const handleTicketListPage = (id,name) => 
  {
    props.SetCurrentOption(name,4)
    setRequestSubTypeID(id)
    setRequestSubTypeName(name)
    setDoThingItemPage(false);
    setTicketListPage(true);

  }
  const renderTicketListPage = () => {
    return (
      <>
      <TicketListPage requestSubTypeName = {requestSubTypeName} problemAreaName = {props.problemAreaName} problemAreaPathwayName={props.problemAreaPathwayName} goBack={props.goBack} goBackDTI={goBackDTI}  goBackPA={props.goBackPA} goBackPAP={props.goBackPAP} requestTypeID = {props.mainRequestTypeId} requestSubTypeID={requestSubTypeID} problemAreaId = {props.problemAreaId} problemAreaPathwayId={props.problemAreaPathwayId} closeSubtype={props.closeSubtype}/>
      </>
    );
  }
  return (
    <>
      <Container className="">
        <Row className="justify-content-between">
          <Col md={12} className='p-0'>
            {doThingItemPage === true ? rebderDoThingItemPage() : ticketListPage === true ? renderTicketListPage() :''}
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(StoreOverviewDoThingsItems);
