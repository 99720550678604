// src/Navbar.js
import React, { useEffect, useState} from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import GetAllMainRequestTypesService from "../../../../../../Service/DashboardServices/HeplDeskServices/GetAllMainRequestTypesService";
import StoreOverviewDoThingsItems from "./StoreOverviewDoThingsItems";
import GetAllProblemAreas from "./GetAllProblemAreas";
import BuildYourBrandFrame1 from "./SubComponents/BuildYourBrandFrame1";
import Buildyourbrands_icon from "../../../../../../designUtils/Storeoverview/Dothings/Buildyourbrands_icon.svg"
import View_Insinghts from "../../../../../../designUtils/Storeoverview/Dothings/view_insinghts.svg"
import Launchsomthingnew_icon from "../../../../../../designUtils/Storeoverview/Dothings/Launchsomthingnew_icon.svg"
import Solveproblems_icon from "../../../../../../designUtils/Storeoverview/Dothings/Solveproblems_icon.svg"
import ArrowIcon_icon from "../../../../../../designUtils/Logo/Vector_Arrow.svg"
import { height } from "@fortawesome/free-solid-svg-icons/faWindowClose";

function StoreOverviewDoThings(props) {

  const [mainRequestType, setMainRequestType] = useState();
  const [currentPage, setCurrentPage] = useState(true);
  const [buildBrandFrame, setBuildBrandFrame] = useState(false);
  const [solveProblemFrame, setSolveProblemFrame] = useState(false);
  const [mainTypeId, setMainTypeId] = useState();
  useEffect(() => {
    GetAllMainRequestTypesService(props.token).then((response) => {
      if (response) {
        setMainRequestType(response);
        props.closeMaintype()
      }
    });    
  }, []);
  const handleClick = (id,requestType ) => {
    props.SetCurrentOption(requestType,1)
    setMainTypeId(id)
    setSolveProblemFrame(true)
    setBuildBrandFrame(false)
    setCurrentPage(false);
  };
  const handleBrandClick = (id,requestType) =>{
    props.SetCurrentOption(requestType,1)
    setMainTypeId(id)
    setBuildBrandFrame(true)
    setSolveProblemFrame(false)
    setCurrentPage(false);
  }
  const goBack = () =>{
    setMainTypeId('')
    setCurrentPage(true);
  }
  const mainType = () =>{
    return (
<Container className="">
          <Row className="justify-content-between">
            <Col md={12}>
            <h5 className="mt-1">What would you like to do? 
            {/* <Button className="float-right btn_class">Get In Touch</Button> */}
             </h5>
            </Col>
            {mainRequestType
              ? mainRequestType.map((type) => (
                <Col md={6} className='mb-3 ' >
                    <a href="#" className="text-decoration-none " onClick={type.requestType == 'BUILD YOUR BRAND' ?() => handleBrandClick(type.id,type.requestType):type.requestType == 'LAUNCH SOMETHING NEW' ? ()=>props.handleProducts():type.requestType === 'SOLVE PROBLEMS'?() => handleClick(type.id,type.requestType): type.requestType === 'VIEW INSIGHTS'? '':''}>
                    <Card className={`card_design p-3  ${type.requestType === 'BUILD YOUR BRAND' ? 'doThingscardBuild1' :type.requestType =='SOLVE PROBLEMS'?'doThingscardsolveprob1':type.requestType == 'LAUNCH SOMETHING NEW' ?'doThingscardlaunch1':type.requestType =='VIEW INSIGHTS'?'viewinsights1':'' }`}>
                    <Row className=" mt-1 p-2">
                    <Col md={4} className={type.requestType === 'BUILD YOUR BRAND' ? 'doThingscardBuild' :  type.requestType =='SOLVE PROBLEMS'?'doThingscardsolveprob':type.requestType == 'LAUNCH SOMETHING NEW' ?'doThingscardlaunch':type.requestType =='VIEW INSIGHTS'?'viewinsights':''}>

                     <img  className= {` rounded mx-auto d-block ${type.requestType == 'VIEW INSIGHTS' ? 'm-2 ':'m-4'}`} src={type.requestType =='SOLVE PROBLEMS' ? Solveproblems_icon :type.requestType == 'LAUNCH SOMETHING NEW' ? Launchsomthingnew_icon : type.requestType == 'BUILD YOUR BRAND' ? Buildyourbrands_icon: type.requestType =='VIEW INSIGHTS'?View_Insinghts:''} alt="not found"/>
                    </Col>
                    <Col md={8} className=" " >
                        <h4 className=" ">{type.requestType}</h4>
                        <p>{type.helpText}</p>
                        <img src={ArrowIcon_icon} className={type.requestType =='SOLVE PROBLEMS'? "rounded float-right mt-5": type.requestType == 'LAUNCH SOMETHING NEW' ?"rounded float-right mt-1": type.requestType == 'VIEW INSIGHTS' ?"rounded float-right mt-5":'rounded float-right mt-4'} alt="not found"/>
                    </Col>
                  </Row>
                <Row >
              <Col >
                   
            </Col>

          </Row>
                   
      </Card>
    </a>

  </Col>
                 
                ))
              : ""}
  </Row>
</Container>
    )
  }

  return (
    <>
    { currentPage ? mainType() : buildBrandFrame ? <BuildYourBrandFrame1  mainRequestTypeId={mainTypeId} goBack={goBack} SetCurrentOption = {props.SetCurrentOption} closeMaintype={props.closeMaintype} closeProblemArea ={props.closeProblemArea}/> : solveProblemFrame ? <GetAllProblemAreas closeMaintype={props.closeMaintype} closeProblemArea ={props.closeProblemArea} closeProblemAreaPathway={props.closeProblemAreaPathway} closeSubtype={props.closeSubtype} mainRequestTypeId={mainTypeId} goBack={goBack} SetCurrentOption = {props.SetCurrentOption}/> : ''}
    {/* { currentPage ? <StoreOverviewDoThingsItems id={mainTypeId} goBack={goBack}/> : mainType() } */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};
export default connect(mapStateToProps)(StoreOverviewDoThings);
