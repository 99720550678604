import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Form,
} from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
// import "../../../RegistrationJourney/Components/ProgressBar.css";
import OPSGetAllTicketsDataService from "../../../../../../Service/OPSServices/OPSTicketServices/OPSGetAllTicketsDataService";
import OpsDashViewTicket from "./Components/OpsDashViewTicket";
import CustomModal from "../../../../../Components/Modals/CustomModal";
import { DropdownList } from "react-widgets";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import OpsDashSWViewTicket from "./Components/OpsDashSWViewTicket";
import Urgent from "../../../../../../designUtils/img/Urgent.svg";
import OPSUpdateTicketAgeDaysService from "../../../../../../Service/OPSServices/OPSTicketServices/OPSUpdateTicketAgeDaysService";
import { width } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { SetInnerHtmlWithClassReplacement } from "../../../../../../Utils/Constants";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function OpsDashTicketListPage(props) {
  const [mainRequestTypes, setMainRequestTypes] = useState();
  const [mainRequestTypeTitle, setMainRequestTypeTitle] = useState("");
  const [problemAreaList, setProblemAreaList] = useState();
  const [problemAreaPathwayList, setProblemAreaPathwayList] = useState();
  const [statusColorDecription, setStatusColorDecription] = useState([]);
  const [subRequestTypes, setSubRequestTypes] = useState();
  const [ticketList, setTicketList] = useState([]);
  const [ticketViewData, setTicketViewData] = useState();
  const [ticketId, setTicketId] = useState();
  const [storeIdForViewTicket, setStoreIdForViewTicket] = useState();
  const [storeNameForViewTicket, setStoreNameForViewTicket] = useState();
  const [ticketStatuslistCount, setTicketStatuslistCount] = useState();


  // ----------
  const [newTicketCount, setNewTicketCount] = useState(0);
  const [resolvedTicketCount, setResolvedTicketCount] = useState(0);
  const [openTicketCount, setOpenTicketCount] = useState(0);
  const [closedTicketCount, setClosedTicketCount] = useState(0);
  const [assignedTicketCount, setAssignedTicketCount] = useState(0);
  const [updatedTicketCount, setUpdatedTicketCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [needInfoicketCount, setNeedInfoTicketCount] = useState(0);
  // ---------- 


  const [
    storeDateTimeForViewTicket,
    setStoreDateTimeForViewTicket,
  ] = useState();
  const [ticketStatuslist, setTicketStatuslist] = useState();
  const [teamMembers, setTeamMembers] = useState();
  // storeOverview
  const [isViewTicketOpen, setIsViewTicketOpen] = useState(false);
  const [isSWViewTicketOpen, setIsSWViewTicketOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [internalQuery, setInternalQuery] = useState("");
  const [searchStore, setSearchStore] = useState("");
  const [statusFilter, setStatusFilter] = useState("Open");
  const [assignedToFilter, setAssignedToFilter] = useState(false);
  const [assignedToFilterValue, setAssignedToFilterValue] = useState(false);
  const [issueDescriptionText, setIssueDescriptionText] = useState("");

  // store list dropdown
  const [storeIntegerId, setStoreIntegerId] = useState("");
  const [store, setStore] = useState("");
  const [showTicketList, setShowTicketList] = useState(false);
  const [issueDescription, setIssueDescription] = useState(false);
  const [showAsin, setShowAsin] = useState(false);
  const [allAsinList, setAllAsinList] = useState([]);
  const [ticketAgeDays, setTicketAgeDays] = useState('');
  const [ticketAgeId, setTicketAgeId] = useState('');
  const [ticketAgeDaysIsOpen, setTicketAgeDaysIsOpen] = useState(false);
  const [ticketAgeDaysColor, setTicketAgeDaysColor] = useState('');
  const [isInternalTicketCheck, setIsInternalTicketCheck] = useState(false);
  const [amazonCaseId, setAmazonCaseId] = useState();
  const [amazonCaseUrl, setAmazonCaseUrl] = useState();

  const setStoreInteger = (value) => {
    setStore(value);
    setStoreIntegerId(value.integerID);
    setShowTicketList(false);
  };
  const getTicketsData = () => {
    if (showTicketList) {
      setShowTicketList(false);
    }
    fetchFilteredTickets(storeIntegerId);
  };

  const filteredTicketList = ticketList.filter((row) => {


    if (query !== "") {
      const storeName = row.storeName.toLowerCase().includes(query.toLowerCase());
      const problemArea = row.problemArea.toLowerCase().includes(query.toLowerCase());
      const problemAreaPathway = row.problemAreaPathway.toLowerCase().includes(query.toLowerCase())
      const requestSubTypes = row.requestSubTypes.toLowerCase().includes(query.toLowerCase())
      const ticketStatus = row.ticketStatus.toLowerCase().includes(query.toLowerCase())
      const issueDescription = row.issueDescription.toLowerCase().includes(query.toLowerCase())
      const priority = row.priority.toLowerCase().includes(query.toLowerCase())
      const createdBy = row.ticketCreateBy.toLowerCase().includes(query.toLowerCase())
      const internalTicket = row.priority.toLowerCase().includes(internalQuery.toLowerCase())
      const ticketId = row.ticketId.toString().toLowerCase().includes(query.toString().toLowerCase())
      let combinedQuery;
      let asin = false
      if (row.firstAsin != null || row.firstAsin != '') {
        asin = row.firstAsin.toString().toLowerCase().includes(query.toLowerCase())
      }
      if (internalQuery != '') {
        combinedQuery = (ticketId || priority || storeName || problemArea || problemAreaPathway || requestSubTypes || issueDescription || ticketStatus || createdBy || asin) && internalTicket
      }
      else {
        combinedQuery = ticketId || priority || storeName || problemArea || problemAreaPathway || requestSubTypes || issueDescription || ticketStatus || createdBy || asin
      }
      if (assignedToFilterValue != "" && assignedToFilterValue != null && row.assignedTo != null && row.assignedTo != '') {
        const words = assignedToFilterValue.split(' ');
        words.pop()
        const resultString = words.join(' ');
        const assignedTo = row.assignedTo.toLowerCase().includes(resultString.toLowerCase())
        if (statusFilter === "All" && assignedTo && combinedQuery) {
          return true;
        }
        else if (statusFilter !== "All" && assignedTo && combinedQuery) {
          if (statusFilter == "Open") {
            return (row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved");
          } else {
            return row.ticketStatus === statusFilter;
          }
        } else if (assignedTo === true && combinedQuery === true) {
          return true;
        }
      }
      else if (statusFilter === "All" && combinedQuery && (assignedToFilterValue == "" || assignedToFilterValue == null)) {
        return true;
      }
      else if (statusFilter !== "All" && combinedQuery && (assignedToFilterValue == "" || assignedToFilterValue == null)) {
        if (statusFilter == "Open") {
          return (row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved");
        } else {
          return row.ticketStatus === statusFilter;
        }
      }
      else if (assignedToFilterValue == "") {
        return combinedQuery;
      }
    }
    //  internal
    else if (assignedToFilterValue != "" && assignedToFilterValue != null && row.assignedTo != null && row.assignedTo != '' && internalQuery != '' && row.priority != null && row.priority != '') {
      const words = assignedToFilterValue.split(' ');
      words.pop()
      const resultString = words.join(' ');
      const internalTicket = row.priority.toLowerCase().includes(internalQuery.toLowerCase())
      const assignedTo = row.assignedTo.toLowerCase().includes(resultString.toLowerCase())
      if (statusFilter === "All" && assignedTo && internalTicket) {
        return true;
      }
      else if (statusFilter !== "All" && assignedTo && internalTicket) {
        if (statusFilter == "Open") {
          return (row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved");
        } else {
          return row.ticketStatus === statusFilter;
        }
      } else if (assignedTo === true && internalTicket) {
        return assignedTo && internalTicket;
      }
    }
    // 
    else if (assignedToFilterValue != "" && assignedToFilterValue != null && row.assignedTo != null && row.assignedTo != '' && internalQuery == '') {
      const words = assignedToFilterValue.split(' ');
      words.pop()
      const resultString = words.join(' ');
      const assignedTo = row.assignedTo.toLowerCase().includes(resultString.toLowerCase())
      if (statusFilter === "All" && assignedTo) {
        return true;
      }
      else if (statusFilter !== "All" && assignedTo) {
        if (statusFilter == "Open") {
          return (row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved");
        } else {
          return row.ticketStatus === statusFilter;
        }
      } else if (assignedTo === true) {
        return assignedTo;
      }
    }
    //  internal working
    else if ((assignedToFilterValue == "" || assignedToFilterValue == null) && internalQuery != '') {
      const internalTicket = row.priority.toLowerCase().includes(internalQuery.toLowerCase())

      if (statusFilter === "All" && assignedToFilterValue == "" && assignedToFilterValue == null && internalTicket) {
        return true;
      }
      else if (statusFilter !== "All" && internalTicket && (assignedToFilterValue == "" || assignedToFilterValue == null)) {
        if (statusFilter == "Open") {
          return (row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved");
        } else {
          return row.ticketStatus === statusFilter;
        }
      }
      else {
        return internalTicket;
      }
    }
    else if (statusFilter === "All" && (assignedToFilterValue == "" || assignedToFilterValue == null)) {
      return true;
    }
    else if (statusFilter !== "All" && (assignedToFilterValue == "" || assignedToFilterValue == null)) {
      if (statusFilter == "Open") {
        return (row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved");
      } else {
        return row.ticketStatus === statusFilter;
      }
    }
  });

  const fetchFilteredTickets = async (IntegerId) => {
    let data = {
      integerId: IntegerId,
    };
    const response = await OPSGetAllTicketsDataService(props.token, data);
    if (response) {
      console.log("OPSGetAllTicketsDataService back");
      console.log(response);
      console.log(response.ticketList[0]);
      console.log(response.ticketList[0].problemArea);

      setTicketList(response.ticketList);
      setTicketStatuslistCount(response.StatusCount);
      setTicketViewData(response.ticketHistory);
      setMainRequestTypes(response.mainRequestTypes);
      response.mainRequestTypes.map((type) => {
        if (props.requestTypeID == type.id) {
          setMainRequestTypeTitle(type.requestType);
        }
      });
      setTeamMembers(response.teamMembers);
      setProblemAreaList(response.problemAreaList);
      setProblemAreaPathwayList(response.problemAreaPathwayList);
      setStatusColorDecription(response.statusColorDecription);
      setSubRequestTypes(response.subRequestTypes);

      setTicketStatuslist(response.ticketStatuslist);
      setShowTicketList(true);
    }
  };
  useEffect(() => {
    fetchFilteredTickets(0);
  }, []);

  const handleCreateAndViewTicketClose = (response) => {
    console.log(response);
    setTicketList(response.ticketList);
    setMainRequestTypes(response.mainRequestTypes);
    setSubRequestTypes(response.subRequestTypes);
    setTicketViewData(response.ticketHistory);
    setTicketStatuslistCount(response.StatusCount);
    setTicketStatuslist(response.ticketStatuslist);
    setIsViewTicketOpen(false);
    setIsSWViewTicketOpen(false);
  };
  const handleAmazonCasePopup = (response) => {
    console.log(response);
    setTicketList(response.ticketList);
    setMainRequestTypes(response.mainRequestTypes);
    setSubRequestTypes(response.subRequestTypes);
    setTicketViewData(response.ticketHistory);
    setTicketStatuslistCount(response.StatusCount);
    setTicketStatuslist(response.ticketStatuslist);
  };
  const handleViewTicket = (
    ticketId,
    mainType,
    integerId,
    storeName,
    amazonCaseId,
    amazonCaseUrl,
    datetime
  ) => {
    setAmazonCaseId(amazonCaseId > 0 ? amazonCaseId : '')
    setAmazonCaseUrl(amazonCaseUrl ? amazonCaseUrl : '#')
    setTicketId(ticketId);
    setStoreIdForViewTicket(integerId);
    setStoreNameForViewTicket(storeName);
    setStoreDateTimeForViewTicket(datetime);
    setMainRequestTypeTitle(mainType);
    setIsViewTicketOpen(true);
  };
  const handleSWViewTicket = (
    ticketId,
    mainType,
    integerId,
    storeName,
    amazonCaseId,
    amazonCaseUrl,
    datetime
  ) => {
    setAmazonCaseId(amazonCaseId > 0 ? amazonCaseId : '')
    setAmazonCaseUrl(amazonCaseUrl ? amazonCaseUrl : '#')
    setTicketId(ticketId);
    setStoreIdForViewTicket(integerId);
    setStoreDateTimeForViewTicket(datetime);
    setStoreNameForViewTicket(storeName);
    setMainRequestTypeTitle(mainType);
    setIsSWViewTicketOpen(true);
  };
  const quickActions = [
    {
      name: () => "SW Tickets Management",
      action: ({
        ticketId,
        requestType,
        integerId,
        storeName,
        amazonCaseId,
        amazonCaseUrl,
        ticketCreatedAt,
      }) => {
        handleSWViewTicket(
          ticketId,
          requestType,
          integerId,
          storeName,
          amazonCaseId,
          amazonCaseUrl,
          ticketCreatedAt
        );
      },
    },
    {
      name: () => "Partner Communication",
      action: ({
        ticketId,
        requestType,
        integerId,
        storeName,
        amazonCaseId,
        amazonCaseUrl,
        ticketCreatedAt,
      }) => {
        handleViewTicket(
          ticketId,
          requestType,
          integerId,
          storeName,
          amazonCaseId,
          amazonCaseUrl,
          ticketCreatedAt,
        );
      },
    },
  ];
  const quickActions1 = [
    {
      name: () => "SW Tickets Management",
      action: ({
        ticketId,
        requestType,
        integerId,
        storeName,
        amazonCaseId,
        amazonCaseUrl,
        ticketCreatedAt,
      }) => {
        handleSWViewTicket(
          ticketId,
          requestType,
          integerId,
          storeName,
          amazonCaseId,
          amazonCaseUrl,
          ticketCreatedAt
        );
      },
    },
  ];
  const quickActionsDropdown = (actions, id) => (
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="link" style={{ textDecoration: 'underline' }}>
        {id}
      </DropdownToggle>
      <DropdownMenu>
        {quickActions.map((qa) => (
          <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>
            {qa.name(actions)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
  const quickActionsDropdown1 = (actions, id) => (
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="link" style={{ textDecoration: 'underline' }}>
        {id}
      </DropdownToggle>
      <DropdownMenu>
        {quickActions1.map((qa) => (
          <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>
            {qa.name(actions)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
  const viewIssueDescription = (text) => {
    setIssueDescription(true);
    setIssueDescriptionText(text);
  };
  const ViewTicketAgeDiscription = (id, text, color) => {
    setTicketAgeId(id)
    setTicketAgeDays(text)
    setTicketAgeDaysColor(color)
    setTicketAgeDaysIsOpen(true)
  }
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const columns = [

    {
      Header: "ID",
      accessor: "ticketId",
      width: 65,
      Cell: (props) => {
        return (
          <>
            {props.original.isInternalTicket ?
              quickActionsDropdown1(props.original, props.value) :
              quickActionsDropdown(props.original, props.value)}
          </>
        );
      },
    },
    {
      Header: "Priority",
      accessor: "priority",
      width: 80,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          {/* <p className="m-0">
            {cellInfo.value === "Urgent" ? (
              <img src={Urgent} alt="not found" />
            ) : (
              cellInfo.value
            )}
          </p> */}
          <p><b>{cellInfo.value}</b></p>
        </div>
      ),
    },
    {
      Header: "Date",
      accessor: "ticketOriginaldate",
      width: 95,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0"><b>{cellInfo.original.ticketCreatedAt}</b></p>
        </div>
      ),
    },
    {
      Header: "Last Updated",
      accessor: "lastModified",
      width: 95,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0"><b>{cellInfo.original.lastModified}</b></p>
        </div>
      ),
    },
    {
      Header: "Updated By",
      width: 100,
      accessor: "ticketUpdatedBy",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span>
            <b>{cellInfo.value}</b>
          </span>
        </div>
      ),
    },
    {
      Header: "Store Name",
      width: 100,
      accessor: "storeName",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0"><b>{cellInfo.value}</b></p>
        </div>
      ),
    },
    {
      Header: "Asin",
      width: 100,
      accessor: "firstAsin",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          {/* <p className="m-0" onClick={()=>showAsinList(cellInfo.original.asinList)}><b>{cellInfo.value}</b></p> */}
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() => showAsinList(cellInfo.value)}
          >
            <b>{cellInfo.value}</b>
          </p>
        </div>
      ),
    },
    {
      Header: "Issue",
      accessor: "issueDescription",
      width: 100,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() =>
              viewIssueDescription(
                cellInfo.value,
                cellInfo.original.issueDescription
              )
            }
          >
            <b>{stripHtmlTags(cellInfo.value)}</b>
          </p>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "ticketStatus",
      width: 75,
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <span>
            <b>{cellInfo.value}</b>
          </span>
        </div>
      ),
    },
    {
      Header: "Created By",
      width: 100,
      accessor: "ticketCreateBy",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span>
            <b>{cellInfo.value}</b>
          </span>
        </div>
      ),
    },
    {
      Header: "Assigned To",
      width: 100,
      accessor: "assignedTo",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span>
            <b>{cellInfo.value}</b>
          </span>
        </div>
      ),
    },
    {
      Header: " Problem Area",
      width: 80,
      accessor: "problemArea",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0"><b>{cellInfo.value}</b></p>
        </div>
      ),
    },
    {
      Header: "Pathway",
      accessor: "problemAreaPathway",
      width: 95,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0"><b>{cellInfo.value}</b></p>
        </div>
      ),
    },
    {
      Header: "Sub Request ",
      width: 95,
      accessor: "requestSubTypes",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0"><b>{cellInfo.value}</b></p>
        </div>
      ),
    },
    // {
    //   Header: "Actions",
    //   width: 75,
    //   Cell: (props) => (<>{props.original.isInternalTicket ? quickActionsDropdown1(props.original) : quickActionsDropdown(props.original)} </>),
    // },

  ];
  const showAsinList = (asinlist) => {
    const asinArray = asinlist.split(',');
    setAllAsinList(asinArray)
    setShowAsin(true)
  }
  const newFilter = (e) => {
    if (e.target.checked) {
      setInternalQuery('Internal Ticket');
      setIsInternalTicketCheck(e.target.checked);
    }
    else {
      setInternalQuery('');
      setIsInternalTicketCheck(e.target.checked);
    }
  }

  const getRowProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.original) {
      return {
        style: {
          background:
            rowInfo.original.priority === "Onboarding Task" ? ''
              : rowInfo.original.ticketStatus === "Closed" ? "#cccccc"
                : rowInfo.original.priority === "Internal Ticket" ? " "

                  : rowInfo.original.bgColor,
          color:
            rowInfo.original.ticketStatus === "Closed"
              ? "#000"
              : rowInfo.original.bgColor
                ? "#000"
                : "#000",
          fontWeight: 300

        },
      };
    }
    return {};
  };

  const UpdateTicketAgeDays = (id) => {

    if (id) {
      const data = {
        id: id,
        ticketAgeDays: ticketAgeDays
      }
      OPSUpdateTicketAgeDaysService(props.token, data).then((response) => {
        if (response) {
          console.log(response);
          setTicketAgeDays(data['ticketAgeDays'])
          setStatusColorDecription(response.statusColorDecription);
          setTicketAgeDaysIsOpen(false)
          window.location.reload();
        }
      });
    }
  }
  const ticketAgeColor = {
    1: '#ffce04',
    2: '#ffc38b',
    3: '#ff8080',

  }
  const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(issueDescriptionText);

  return (
    <section className="">
      <Container fluid className="p-0">
        <Row className="m-0">
          {/*  <Col md={12} className="p-0">
            <Card className="main-card mb-3 mt-3">
            <CardBody>
              <Row md="12">
               <Col md="2 ">
                <CardTitle className='mt-2' style={{ fontSize: '15px' }}>Tickets</CardTitle>
                </Col> 
                <Col md={5 }>
                  <DropdownList
                    data={props.stores}
                    dataKey="id"
                    textField="name"
                    placeholder="Select Store"
                    value={store}
                    onChange={(value) => {
                      setStoreInteger(value);
                    }}
                  />
                 </Col>
                 <Col md={7}>
                  <LoadingButton
                    className="button-ticket ml-4"
                    onClick={getTicketsData}
                  >
                    Fetch Tickets 
                  </LoadingButton>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>*/}
          <Col md={12} className="p-0">
            <Card className="main-card mb-3 mt-3">
              <CardBody>
                <Row>
                  <Col md="12">
                    <CardTitle className='mt-2 border mb-0 p-2 text-center' style={{ fontSize: '20px', color: '#243837', backgroundColor: '#B1FA63' }}>Ticket Status</CardTitle>
                    <div className="d-flex border p-1 justify-content-around">
                      {
                        ticketStatuslistCount ?
                          ticketStatuslistCount.map((item) => (
                            <div className="border pt-2 pb-2 pl-2 pr-2 m-1">
                              <h4 className="text-center" style={{ color: item.color }}><b>{item.count}</b></h4>
                              <h5 className="text-center" style={{ color: item.color }}><b>{item.status}</b></h5>
                            </div>
                          ))
                          :
                          <p>Loading Ticket Statistics...</p>
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {showTicketList ? (
            <Col md="12" className="mbg-3 p-0">
              <Card className="main-card mb-3 mt-3">
                <CardBody>
                  <Row className='mb-2'>
                    <Col md={7}>
                      <p><b>TICKET LIST</b></p>
                      <div className="row">
                        <Col md={4}>
                          <label>
                            <input
                              type="text"
                              placeholder="Search Text"
                              value={query}
                              className="search form-control "
                              onChange={(e) => {
                                setQuery(e.target.value);
                                setAssignedToFilter(true)
                              }}
                            />
                          </label>
                        </Col>
                        <Col md={5}>
                          <select
                            id="assignedTo"
                            name="assignedTo"
                            onChange={(e) => {
                              setAssignedToFilterValue(e.target.value);
                              setAssignedToFilter(true)
                            }}
                            value={assignedToFilterValue ? assignedToFilterValue : ''}
                            className="form-control "
                          >
                            <option value="">-- Assigned To --</option>
                            {teamMembers
                              ? teamMembers.map((member) => (
                                <option value={member.employeeName} title={`${member.note}`}>
                                  {member.employeeName}
                                </option>
                              ))
                              : ""}
                          </select>

                        </Col>
                        <Col md={3} className='mt-2'>
                          <span className="ml-4 internal-ticket-checkbox ">
                            <input
                              value={internalQuery}
                              type="checkbox"
                              checked={isInternalTicketCheck}
                              onChange={(e) => {
                                newFilter(e)
                              }}
                            />&nbsp;
                            <b>Internal</b>
                          </span>
                        </Col>
                      </div>
                    </Col>

                    <Col md={5} className=''>
                      <p className="text-center"><b>TICKET AGE COLORING</b></p>
                      <div className="row justify-content-center">
                        {
                          Object.values(ticketAgeColor).map((ticket) => {
                            return (
                              <div className="col-3">
                                {statusColorDecription.map((item) => (
                                  item.colourCode === ticket ? <p onClick={() => ViewTicketAgeDiscription(item.id, item.ticketAgeDays, item.colourCode)} className="text-center p-2 mb-0" style={{ backgroundColor: item.colourCode }}>
                                    <b>{item.ticketAgeDays} days</b>
                                  </p> : ''
                                ))}
                              </div>
                            );
                          })
                        }
                      </div>

                      {/* </div> */}
                    </Col>
                  </Row>
                  <span className="mb-2 mt-4" style={{ marginLeft: "0px" }}>
                    <input
                      type="radio"
                      name="statusFilter"
                      value={statusFilter}
                      checked={statusFilter === "All"}
                      className="ml-2"
                      onChange={() => {
                        setStatusFilter("All");
                        setAssignedToFilter(false)
                      }}
                    />
                    &nbsp;
                    <b>All</b>
                  </span>
                  <span className="mb-2" style={{ marginLeft: "30px" }}>
                    <input
                      type="radio"
                      name="statusFilter"
                      value={statusFilter}
                      checked={statusFilter === "Open"}
                      className="ml-2 mt-2 mb-3"
                      onChange={() => {
                        setStatusFilter("Open");
                        setAssignedToFilter(false)
                      }}
                    />
                    &nbsp;
                    <b>Open</b>
                  </span>

                  {ticketStatuslist
                    ? ticketStatuslist.map((status) => (
                      <span className="mb-2" style={{ marginLeft: "30px" }}>
                        <input
                          type="radio"
                          name="statusFilter"
                          value={status.ticketStatus}
                          checked={statusFilter === status.ticketStatus}
                          className="ml-2 mt-2 mb-3"
                          onChange={() => {
                            setStatusFilter(status.ticketStatus);
                            setAssignedToFilter(false)
                          }
                          }
                        />
                        &nbsp;
                        <b>{status.ticketStatus}</b>

                      </span>
                    ))
                    : ""}



                  <ReactTable
                    data={filteredTicketList}
                    columns={columns}
                    getTrProps={getRowProps}
                    getTdProps={(state, rowInfo, column, instance) => {

                      // Check if the current cell belongs to the first column
                      //  && rowInfo.original.priority === "Internal Ticket"
                      if (column.id === "priority" || column.id === 'ticketId') {
                        if (rowInfo && rowInfo.original.priority === "Internal Ticket") {
                          return {
                            style: {
                              backgroundColor: '#f7ddee', // Set pink background color for ID 10
                              // Add any other styles you need
                            },
                          };
                        }
                      }
                      if (column.id === "priority" || column.id === 'ticketId') {
                        if (rowInfo && rowInfo.original.priority === "Onboarding Task") {
                          return {
                            style: {
                              backgroundColor: '#96e0e5',
                            },
                          };
                        }
                      }
                      return {
                        // style: {
                        //   backgroundColor: '#000000', 
                        // },
                      };
                    }}
                    className="-fixed -highlight -striped"
                    getTheadProps={() => {
                      return {
                        style: {
                          overflowY: "hidden",
                          background: "#B1FA63",
                          color: "#243837",
                        },
                      };
                    }}
                    defaultPageSize={25}
                    noDataText={"No Data Found."}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>

      <CustomModal
        header={
          <div>
            <p className="mb-0">
              {storeNameForViewTicket}
            </p>
            <p className="mb-0 d-flex justify-content-between">
              <div className="d-flex">
                <span><b>Ticket Id :</b> {ticketId}</span>
                <span className="ml-3">{ticketList[0] ? ticketList[0].problemArea : ''}</span>
                <span className="ml-3">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
                <span className="ml-3">{ticketList[0] ? ticketList[0].problemAreaPathway : ''}</span>
              </div>
              <span className="ml-3">
                {storeDateTimeForViewTicket}
              </span>
            </p>
          </div>

        }
        isOpen={isViewTicketOpen}
        toggle={() => setIsViewTicketOpen(!isViewTicketOpen)}
        size={"xl"}
        className="bgColor"

      >
        <OpsDashViewTicket
          isFromCreatingTicket={false}
          ticketId={ticketId}
          ticketList={ticketList}
          ticketStatuslist={ticketStatuslist}
          mainRequestTypes={mainRequestTypes}
          subRequestTypes={subRequestTypes}
          ticketViewData={ticketViewData}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          handleAmazonCasePopup={handleAmazonCasePopup}
          problemAreaPathwayList={problemAreaPathwayList}
          problemAreaList={problemAreaList}
          problemAreaId={props.problemAreaId}
          problemAreaPathwayId={props.problemAreaPathwayId}
          storeIdForViewTicket={storeIdForViewTicket}
          storeNameForViewTicket={storeNameForViewTicket}
          storeDateTimeForViewTicket={storeDateTimeForViewTicket}
          amazonCaseId={amazonCaseId}
          amazonCaseUrl={amazonCaseUrl}
        />
      </CustomModal>
      <CustomModal
        header={
          <div>
            <p className="mb-0">
              {storeNameForViewTicket}
            </p>
            <p className="mb-0 d-flex justify-content-between">
              <div className="d-flex">
                <span><b>Ticket Id :</b> {ticketId}</span>
                <span className="ml-3">{ticketList[0] ? ticketList[0].problemArea : ''}</span>
                <span className="ml-3">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
                <span className="ml-3">{ticketList[0] ? ticketList[0].problemAreaPathway : ''}</span>
              </div>
              <span className="ml-3">
                {storeDateTimeForViewTicket}
              </span>
            </p>
          </div>
        }

        isOpen={isSWViewTicketOpen}
        toggle={() => setIsSWViewTicketOpen(!isSWViewTicketOpen)}
        size={"xl"}
        className="bgColor"
      >
        <OpsDashSWViewTicket
          ticketId={ticketId}
          isFromCreatingTicket={false}
          ticketList={ticketList}
          teamMembers={teamMembers}
          ticketStatuslist={ticketStatuslist}
          mainRequestTypes={mainRequestTypes}
          subRequestTypes={subRequestTypes}
          ticketViewData={ticketViewData}
          handleAmazonCasePopup={handleAmazonCasePopup}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          problemAreaPathwayList={problemAreaPathwayList}
          problemAreaList={problemAreaList}
          problemAreaId={props.problemAreaId}
          problemAreaPathwayId={props.problemAreaPathwayId}
          storeIdForViewTicket={storeIdForViewTicket}
          storeNameForViewTicket={storeNameForViewTicket}
          storeDateTimeForViewTicket={storeDateTimeForViewTicket}
          isInternalTicketCheck={isInternalTicketCheck}
          amazonCaseId={amazonCaseId}
          amazonCaseUrl={amazonCaseUrl}
        />
      </CustomModal>
      <CustomModal
        header={"Issue Description"}
        isOpen={issueDescription}
        toggle={() => setIssueDescription(!issueDescription)}
        size={"lg"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIssueDescription(!issueDescription)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} />
          {/* <p>{issueDescriptionText}</p> */}
        </Label>
      </CustomModal>
      <CustomModal
        header={"ASIN List"}
        isOpen={showAsin}
        toggle={() => setShowAsin(!showAsin)}
        size={"md"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setShowAsin(!showAsin)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <ul>
            {allAsinList && allAsinList.map((asin, index) => (
              <li key={index}>{asin}</li>
            ))}
          </ul>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Update"}
        isOpen={ticketAgeDaysIsOpen}
        toggle={() => setTicketAgeDaysIsOpen(!ticketAgeDaysIsOpen)}
        size="sm"
        className='card-of-problemarea bgColor '
      >
        <Card className='p-0 card-of-problemarea  ' >
          <Container >
            <div className=" p-0">
              <div className="">
                <div className="text-center mx-auto mb-3">
                </div>

                <Form>
                  <Row form className='pt-1 pl-2 pr-2 pb-3'>
                    <Col md={12}>
                      <Label>
                        <div className="d-flex justify-content-center"><h6 className=" text-white p-2" style={{ backgroundColor: ticketAgeDaysColor }}>Ticket Age Days :</h6> <input className="form-control w-25 ml-3" value={ticketAgeDays} onChange={(e) => setTicketAgeDays(e.target.value)} /> </div>
                        <p className="text-center  mb-0 mt-3"><Button className="btn_class" onClick={() => UpdateTicketAgeDays(ticketAgeId)}>Submit</Button></p>
                      </Label>
                    </Col>

                  </Row>
                </Form>
              </div>
            </div>
          </Container>
        </Card>
      </CustomModal>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    stores: state.accountOverview.landing.adProfiles,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(OpsDashTicketListPage);
