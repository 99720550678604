import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Input, Button } from 'reactstrap'
import BrandAssetGallery from './Components/BrandAssetGallery';
import ProductAssetsGallery from './Components/ProductAssetsGallery';
import GetAllProblemAreaPathwaysService from '../../../../../../../../Service/DashboardServices/HeplDeskServices/GetAllProblemAreaPathwaysService';
import { connect } from "react-redux";
import CampaignAssetGallery from './Components/CampaignAssetGallery';
import BackButton from "../../../../.././../../../designUtils/Logo/BackButton.svg"
import AssetsGallery_Thin from '../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/AssetsGallery_Thin.svg'

function AssetGalleryMainPage(props) {
    const [campaignAssetPage, SetCampaignAssetPage] = useState(false);
    const [brandAssetsPage, setBrandAssetPage] = useState(true);
    const [productAssetPage, setProductAssetPage] = useState(false);
    const [problemAreaPathwayList, setProblemAreaPathwayList] = useState()
    const [selectedTab, setSelectedTab] = useState(false)

    const handleAssetGalleryPage = (id) => {
        setSelectedTab(id)
        switch (id) {
            case 10:
                setBrandAssetPage(true);
                setProductAssetPage(false);
                SetCampaignAssetPage(false);
                break;
            case 11:
                setBrandAssetPage(false);
                setProductAssetPage(true);
                SetCampaignAssetPage(false);
                break;
            case 12:
                setBrandAssetPage(false);
                setProductAssetPage(false);
                SetCampaignAssetPage(true);
                break;
            default:
                setBrandAssetPage(true);
                setProductAssetPage(false);
                SetCampaignAssetPage(false);
        }
    }

    useEffect(() => {
        const data = {
            mainRequestTypeId: props.mainRequestTypeId,
            problemAreaId: props.problemAreaId,
        };
        console.log(props.token)
        const response = GetAllProblemAreaPathwaysService(props.token, data).then(
            (response) => {
                if (response) {
                    setProblemAreaPathwayList(response);
                    console.log(response)
                    console.log('response')
                }
            }
        );
    }, []);

    return (
        <Container>
            <Row>
                <Col md={12}>

                    <Card className='p-2 mb-3 child-card' >
                        {/* <Col md={12}>
                            <div className='d-flex'>

                                <Input
                                    type="text"
                                    className="form-control w-25"
                                    placeholder="Search"
                                />
                            </div>
                            <hr className='line'></hr>
                        </Col> */}
                        <Col md={12}>
                            <h4>
                                <button onClick={() => { props.goBackPA() }} className="hover-btn-link back-btn mr-1 "  >
                                    <img src={BackButton} alt="not found" />
                                </button>
                                <img src={AssetsGallery_Thin} alt=' not found' />
                                &nbsp; <b>Asset Gallery</b></h4>
                            <hr className='line'></hr>
                        </Col>
                        <Col md={12}>
                            <div className='d-flex'>
                                {
                                    problemAreaPathwayList && problemAreaPathwayList.map((pa, index) => (
                                       
                                        <>
                                        {console.log(pa)}
                                            {selectedTab === false && index === 0 ? setSelectedTab(pa.id) : ''}
                                            <Button
                                                className={`assets-button-switch ${selectedTab == pa.id ? 'btn-background-color-pathways' : ''} `}
                                                onClick={() => handleAssetGalleryPage(pa.id)}
                                            >
                                                {pa.problemAreaPathway}
                                            </Button>
                                        </>))
                                }
                            </div>
                        </Col>
                    </Card>
                    {brandAssetsPage === true ? <BrandAssetGallery mainRequestTypeId = {props.mainRequestTypeId} problemAreaId = {props.problemAreaId} pathwayId={selectedTab} /> : ''}
                    {productAssetPage === true ? <ProductAssetsGallery /> : ''}
                    {campaignAssetPage === true ? <CampaignAssetGallery /> : ''}
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
    };
};

export default connect(mapStateToProps)(AssetGalleryMainPage);