import React, { Fragment } from 'react';
import {
    Card, CardHeader,
    ListGroup, ListGroupItem
} from "reactstrap";


/**
 * Creates a card which contains a list of widgets.
 * Props:
 * listHeading (optional) - Header text for card
 * widgets - array of dictionaries that each contain the information to be displayed on each widget list item
 * renderWidget - callback function that renders each widget component
 * renderWidgetListItem - callback function that renders each widget component enclosed by a ListGroupItem,
 * takes in the item to render and the key of the ListGroupItem. This needs to be used for SelectableWidget's to ensure proper highlighting upon selection.
 * card - widget list is displayed in a Card component if true.
 */
function WidgetListCard(props) {

    if (!props.widgets || (!props.renderWidget && !props.renderWidgetListItem)) return;

    const renderListItem = (item, idx) => {

        if (!item) return;

        if (props.renderWidgetListItem) {
            return (
                props.renderWidgetListItem(item, idx)
            );
        } else {
            return (
                <ListGroupItem key={idx}>
                    {props.renderWidget(item)}
                </ListGroupItem>
            );
        }
    }

    return (
        <Fragment>
            {
                props.card ?
                    <div className={props.className !== undefined ? props.className : "main-card mb-3"} style={props.style}>
                        { props.listHeading &&
                        <div>
                            {props.listHeading}
                        </div>
                        }
                        <ListGroup flush>
                            { props.widgets.map( (item, idx) => renderListItem(item, idx) ) }
                        </ListGroup>
                    </div>
                    :
                    <ListGroup flush>
                        { props.widgets.map( (item, idx) => renderListItem(item, idx) ) }
                    </ListGroup>
            }

        </Fragment>
    );
}

export default WidgetListCard;
