import React, { Fragment, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    Label,
  } from "reactstrap";
import { connect } from "react-redux";
import GetAllProblemAreasService from '../../../../../../../Service/DashboardServices/HeplDeskServices/GetAllProblemAreasService';
import GetAllProblemAreaPathways from "../GetAllProblemAreaPathways";
import BuildYourBrandFrame2 from "./InnerSubComponents/BuildYourBrandFrame2";
import ArrowIcon_icon from "./../../../../../../../designUtils/Logo/Vector_Arrow.svg"
import Buildyourbrands_icon from "../../../../../../../designUtils/Storeoverview/Dothings/Buildyourbrands_icon.svg"
import CreateBrand from "./../../../../../../../designUtils/Storeoverview/BuildBrand/CreateBrand.svg"
import VisitContaint from "./../../../../../../../designUtils/Storeoverview/BuildBrand/VisitContaint.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackButton from "../../../../../../../designUtils/Logo/BackButton.svg"
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import CreateBrandProfileMainPage from "./Questionnaire/CreateBrandProfileMainPage";
import ExistBrandProfileList from "./Questionnaire/Components/ExistBrandProfileList";
import AssetGalleryMainPage from "./AssetGallery/AssetGalleryMainPage";
import AssetsGallery_Thin from '../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/AssetsGallery_Thin.svg'
function BuildYourBrandFrame1(props) {
  const [problemAreaList, setProblemAreaList] = useState();
  const [problemAreaId, setProblemAreaID] = useState();
  const [problemAreaName, setProblemAreaName] = useState();
  const [mainRequestTypeId, setMainRequestTypeId] = useState();
  const [currentPage, setCurrentPage] = useState(false);
  const [galleryAssets , setGalleryAssets] =useState(false);

  useEffect(() => {
    const data = {
        mainRequestTypeId: props.mainRequestTypeId,
    };
     GetAllProblemAreasService(props.token, data).then((response) => {
        if (response) {
          setProblemAreaList(response);
        }
      }
    );
  }, []);
 
const handleClick = (id,name) =>{
  props.SetCurrentOption(name,2)
  setProblemAreaID(id)
  setProblemAreaName(name)
  setMainRequestTypeId(props.mainRequestTypeId)
  setCurrentPage(true);

}
const handleClickAssetGallery =(id, name) =>{
  setProblemAreaID(id)
  setProblemAreaName(name)
  setMainRequestTypeId(props.mainRequestTypeId)
  setCurrentPage(false);
  setGalleryAssets(true)
}
const goBackPA = () => {
  setCurrentPage(false);
  setGalleryAssets(false)
}

const BuildYourBrandFrame = () => {
  return (
    <>
   <Container className="">
          <Row className="">
            <Col md={12}>
              
            <h5 className="mt-1">
              <button onClick={()=>{props.goBack();props.closeMaintype()}} className="back-btn"  >
            <img  className='m-2'src={BackButton} alt="not foun"/>
                </button>
               <img style={{height:40}} src={Buildyourbrands_icon} alt=" not found"/>  Build Your Brand. </h5>
            <hr className="line"></hr>
            {/* <Button className="float-right btn_class">Get In Touch</Button> */}
             {/* </h5> */}
            </Col>
            {problemAreaList
              ? problemAreaList.map((type) => (
                <Col md={4} className="mt-1 p-2 card-of-problemarea">
                {type.problemArea == 'Visit Content e-store' ? <a href='https://shop.simpliworks.io/'  className="text-decoration-none text-reset"  target="_blank">
                   <Card className="  buildBrandsVisit"style={{height: 274}} >
                    <Col md={12} className=' p-0'>
                      <Col md={12}  >
                        <img className=' mx-auto d-block buildBrandsVisit1 mt-2 ' style={{height:150}}src={CreateBrand} alt='not found'/>
                      </Col>
                      <CardTitle className="mb-0 buildBrandsVisit2 mt-3" style={{height: 100}}>
                      <h4 className="text-center mt-3 " style={{color:"black"}}>{type.problemArea}</h4>
                      <p>{type.helpText}</p>
                      <img  className='rounded float-right mt-1 mr-4 ' src={ArrowIcon_icon} />
                    
                    </CardTitle>
                    </Col>

                   </Card>
                </a>
                :
                type.problemArea === 'ASSET GALLERY'?
                <a href="#" className="text-decoration-none " onClick={() => handleClickAssetGallery(type.id,type.problemArea)}>
                  
                  <Card className=" buildBrandsVisit "style={{height: 274}} >
                    <Col md={12} className='   p-0'>
                      <Col md={12}  >
                        <img className=' mx-auto d-block buildBrandsVisit1 mt-2 ' style={{height:150}}src={AssetsGallery_Thin} alt='not found'/>
                      </Col>
                      <CardTitle className="mb-0 buildBrandsVisit2 mt-3" style={{height: 100}}>
                      <h4 className="text-center mt-3 " style={{color:"black"}}>{type.problemArea}</h4>
                      <img  className='rounded float-right mt-2 mr-4' src={ArrowIcon_icon} />
                    </CardTitle>
                    </Col>

                   </Card>
                </a>
                :
                <a href="#" className="text-decoration-none " onClick={() => handleClick(type.id,type.problemArea)}>
                  
                  <Card className=" buildBrandsVisit "style={{height: 274}} >
                    <Col md={12} className='   p-0'>
                      <Col md={12}  >
                        <img className=' mx-auto d-block buildBrandsVisit1 mt-2 ' style={{height:150}}src={VisitContaint} alt='not found'/>
                      </Col>
                      <CardTitle className="mb-0 buildBrandsVisit2 mt-3" style={{height: 100}}>
                      <h4 className="text-center mt-3 " style={{color:"black"}}>{type.problemArea}</h4>
                      <img  className='rounded float-right mt-2 mr-4' src={ArrowIcon_icon} />
                    </CardTitle>
                    </Col>

                   </Card>
                </a>}
                
              </Col>
                ))
              : ""}
          </Row>
        </Container>
    </>
    )
}
  return(
    <>
     { currentPage ?
     (<ExistBrandProfileList goBackPA={goBackPA}/>) 
     :galleryAssets ? (
     <AssetGalleryMainPage problemAreaName={problemAreaName}
      problemAreaId = {problemAreaId}
      mainRequestTypeId={mainRequestTypeId}
      goBackPA={goBackPA}/>)
     :BuildYourBrandFrame() }
     
     {/* { currentPage ? <BuildYourBrandFrame2 problemAreaName={problemAreaName} problemAreaId = {problemAreaId} mainRequestTypeId={mainRequestTypeId} goBack={props.goBack} goBackPA={goBackPA} closeProblemArea ={props.closeProblemArea}/> : BuildYourBrandFrame() } */}
    </>
  )
}
const mapStateToProps = (state) => {
    return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      error: state.loggedUser.error,
      newUser: state.loggedUser.newUser,
      needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
      user: state.LWA.user,
      isAuth: state.LWA.isAuth,
      enableMobileMenu: state.theme.enableMobileMenu,
      enableClosedSidebar: state.theme.enableClosedSidebar,
      storeOverview: state.accountOverview.store.storeOverview,
    };
  };
  
  export default connect(mapStateToProps)(BuildYourBrandFrame1);