import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function RequestHistDataAdvReportsTaskService(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    //return axios.post(`${CURRENT_API_URL}/sellerPartner/RequestHistDataAdvReportsTask`, data, {
    return axios.post(`${CURRENT_API_URL}/amazonreports/Taiji_RequestAdvertisingReports`, data, {
        
        headers: {
            'Authorization': token,
        }
        
    }).then( notify.NotifySuccess("Running Request Hist Advertising Reports."))        
        .then(res => {
            notify.NotifySuccess("Historical Advertising Reports have been requested successfully.");
            return res.data;           
        })
            .catch(() => {
                notify.NotifyError("An error occurred while running request advertising reports job.");
                return false;
            });
}


export {
    RequestHistDataAdvReportsTaskService,
}