import React, { Fragment, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    Label,
  } from "reactstrap";
import { connect } from "react-redux";
import GetAllProblemAreasService from '../../../../../../Service/DashboardServices/HeplDeskServices/GetAllProblemAreasService';
import GetAllProblemAreaPathways from "./GetAllProblemAreaPathways";
import TicketListPage from "../../../TroubleTickets/TicketListPage";
import Advertising_icon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Advertising.svg"
import SellerCentral_icon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/SellerCentral.svg"
import ButtonIcon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/ButtonIcon.svg"
import ArrowIcon_icon from "../../../../../../designUtils/Logo/Vector_Arrow.svg"
import YourAccount_icon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/YourAccount.svg"
import { width } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import Solveproblems_icon from "../../../../../../designUtils/Storeoverview/Dothings/Solveproblems_icon.svg"
import BackButton from "../.././../../../../designUtils/Logo/BackButton.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
function GetAllProblemAreas(props) {
  const [problemAreaList, setProblemAreaList] = useState();
  const [problemAreaId, setProblemAreaID] = useState();
  const [problemAreaName, setProblemAreaName] = useState();
  const [mainRequestTypeId, setMainRequestTypeId] = useState();
  const [currentPage, setCurrentPage] = useState(true);
  const [problemAreaPathwayFrame, setProblemAreaPathwayFrame] = useState(false);
  const [ticketListFrame, setTicketListFrame] = useState(false);
  useEffect(() => {
    const data = {
        mainRequestTypeId: props.mainRequestTypeId,
    };
     GetAllProblemAreasService(props.token, data).then((response) => {
        if (response) {
          console.log("GetAllProblemAreas")
          console.log(response)
          setProblemAreaList(response);
        }
      }
    );
  }, []);
const handleClick = (id,name) =>{
  props.SetCurrentOption(name,2)
  setProblemAreaID(id)
  setProblemAreaName(name)
  setProblemAreaPathwayFrame(true)
  setTicketListFrame(false)
  setMainRequestTypeId(props.mainRequestTypeId)
  setCurrentPage(false);
}
const goBackPA = () => {
  setCurrentPage(true);
}
const renderTicketList = () =>{
  setMainRequestTypeId(props.mainRequestTypeId)
  setTicketListFrame(true)
  setProblemAreaPathwayFrame(false)
  setCurrentPage(false);
}
const problemAreaPage = () => {
  return (
    <>
   <Container className="">

          <Row className="justify-content-between">
            <Col md={12}>
              
            <h5 className="mt-1">   
              <button onClick={()=>{props.goBack();props.closeMaintype()}} className="hover-btn-link back-btn"  >
              <img  className='m-2'src={BackButton} alt="not foun"/>
              </button> 
              <img src={Solveproblems_icon} style={{height:40}} alt="not found" className=" mr-1"/>  
              Please select your problem area. 
            <Button className="float-right btn btn-light" onClick={()=>renderTicketList()}> <img style={{height:25}} src={ButtonIcon}/> Tickets</Button>
             </h5>
            </Col>
            {problemAreaList
              ? problemAreaList.map((type) => (
                <Col md={4} className="mt-1 p-2 card-of-problemarea ">
                <a href="#" className="text-decoration-none " onClick={() => handleClick(type.id,type.problemArea)}>
                  <Card  style={{ borderRadius: '30px' }} >
                    <div className='problemArea1 p-4 '>
                    <img  style={{height:100}}src={type.problemArea =='Seller Central' ? SellerCentral_icon :type.problemArea == 'Advertising' ? Advertising_icon : type.problemArea == 'Simpliworks Account' ? YourAccount_icon:  type.problemArea == 'WorkflowItem1' ? YourAccount_icon:''} alt="not found"/>
                    </div>
                    <div className="mb-0 problemArea p-3 " style={{height: 100}}>
                        <h4 className="text-center mt-3" style={{color:"black"}}>{type.problemArea}</h4>
                        <img src={ArrowIcon_icon} className=" float-right mt-0" alt="not found"/>
                    </div>
                  </Card>
                </a>
              </Col>
                ))
              : ""}
          </Row>
        </Container>
    </>
    )
}
  return(
    <>
     { currentPage ? problemAreaPage() : problemAreaPathwayFrame ? <GetAllProblemAreaPathways problemAreaName={problemAreaName} problemAreaId = {problemAreaId} mainRequestTypeId={mainRequestTypeId} goBack={props.goBack} goBackPA={goBackPA} SetCurrentOption={props.SetCurrentOption} closeProblemArea ={props.closeProblemArea} closeProblemAreaPathway={props.closeProblemAreaPathway} closeSubtype={props.closeSubtype}/> : ticketListFrame ? <TicketListPage requestTypeID={props.mainRequestTypeId} problemAreaId={0} requestSubTypeID={0} problemAreaPathwayId={0} goBack={props.goBack} goBackPA={goBackPA}/>: '' }
    </>
  )
}
const mapStateToProps = (state) => {
    return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      error: state.loggedUser.error,
      newUser: state.loggedUser.newUser,
      needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
      user: state.LWA.user,
      isAuth: state.LWA.isAuth,
      enableMobileMenu: state.theme.enableMobileMenu,
      enableClosedSidebar: state.theme.enableClosedSidebar,
    };
  };
  
  export default connect(mapStateToProps)(GetAllProblemAreas);
