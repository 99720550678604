// const rulesMap = [
//     {
//         rule: "home:simple",
//         roles: [0, 1, 2, 3],
//     },
// ];

const rules = {
    0: { // odin
        static: [
            "home:simple",
            "profile:logout",
            "profile:userProfile",
            "profile:Store-Overview",
            "profile:authorization",
            "profile:addKeywordstracking",
            "profile:spapiAuth",
            "profile:adApiAuth",
            "profile:advertisingAuth",
            "payment:customerPortal",
            "accounts:view",
            "accounts:create",
            "accounts:delete",
            "sellers:view",
            "sellers:assign",
            "stores:refreshData",
            "campaigns:view",
            // "campaigns:create",
            // "campaigns:launch",
            "campaigns:relaunch",
            "campaigns:edit",
            "campaigns:import",
            "products:add",
            "products:edit",
            "products:view",
            "products:tracking",
            "products:delete",
            "products:updateskus",
            "products:keywordExport",
            "products:productPerformanceReport",
            "products:CampaignPerformanceReport",
            "products:WeeklySearchTermReport",
            "products:WeeklySalesReport",
            "products:SalesTrendsReport",
            "products:TargetPerformanceReport",
            "products:StoreOverviewReport",
            "products:StoreOverviewPrimeReport",
            "products:updateBudget",
            "products:allocateBudget",
            "products:updateStage",
            "keywords:refreshData",
            "keywordAlerts:view",
            // "project:create",
            // "project:edit",
            // "project:view",
            // "project:review",
            "credits:view",
            "pricing:view",
            "pricingOptions:view",
            "addOns:view",
            "odinPackage:purchase",
            "searchTerm:upload",
            "changelog:view",
            'stores:view',
            'contentAgencies:view',
            'contentAgencies:create',
            'brandProfile:edit',
            'brandProfile:view',
            'leadGeneration:view',
            'lgResearchByProductCategory:view' ,
            'lgResearchjobs:view',
            'lgAmazonProductCategories:view',
            'ops:view',
            'ops:dataCollection',
            'ops:campaignLaunch',
            'ops:productEnrollment',
            'ops:productBudgetUpdate',
            'ops:userAuthorization',
            'ops:sellerPartnerData',
            'ops:advertisingData',
            'ops:advertisingJob',
            'ops:ticketList',
            'ops:storeHealth',
            'ops:workflows',
            'registrationJourney:view',
            'ticket:view',
            'store:view',

        ],
    },
    1: { // frigg
        static: [
            "home:standard",
            "profile:userProfile",
            "profile:spapiAuth",
            "profile:adApiAuth",
            "profile:logout",
            "profile:Store-Overview",
            "accounts:create",
            "accounts:delete",
            // "sellers:view",
            // "sellers:assign",
            // "campaigns:view",
            // "campaigns:create",
            // "campaigns:launch",
            // "campaigns:edit",
            // "products:add",
            // "products:edit",
            // "products:view",
            // "project:create",
            "project:edit",
            "project:view",
            // "credits:view",
            'brandProfile:view',
            'registrationJourney:view',
            'store:view'
        ],
        // dynamic: {
        //     "posts:edit": ({userId, postOwnerId}) => {
        //         if (!userId || !postOwnerId) return false;
        //         return userId === postOwnerId;
        //     },
        // },
    },
    2: { // thor
        static: [
            "home:simple",
            "profile:spapiAuth",
            "profile:adApiAuth",
            "profile:logout",
            "profile:userProfile",
            //"profile:authorization",
            "profile:advertisingAuth",
            "subAccounts:create",
            "subAccounts:view",
            "subAccounts:delete",
            "sellers:view",
            "sellers:assign",
            // "campaigns:view",
            // "campaigns:create",
            // "campaigns:launch",
            // "campaigns:edit",
            "products:add",
            "products:edit",
            "products:view",
            "products:delete",
            "products:updateskus",
            "products:keywordExport",
            "products:productPerformanceReport",
            "products:TargetPerformanceReport",
            "products:StoreOverviewReport",
            "products:StoreOverviewPrimeReport",
            "products:CampaignPerformanceReport",
            "products:WeeklySalesReport",
            "products:SalesTrendsReport",
            "products:WeeklySearchTermReport",
            "products:updateBudget",
            // "credits:view",
            "pricing:view",
            // "pricingOptions:view",
            "addOns:view",
            "stores:view",
            'brandProfile:edit',
            'registrationJourney:view',           
        ],
    },
    3: { // loki
        static: [
            "profile:logout",
            "profile:userProfile",
            //"profile:authorization",
            "profile:addKeywordstracking",
            "profile:spapiAuth",
            "profile:Store-Overview",
            "profile:adApiAuth",
            "profile:advertisingAuth",
            "payment:customerPortal",
            "products:add",
            "products:edit",
            "products:view",
            "products:delete",
            "products:updateskus",
            "products:keywordExport",
            "products:productPerformanceReport",
            "products:TargetPerformanceReport",
            "products:StoreOverviewReport",
            "products:StoreOverviewPrimeReport",
            "products:CampaignPerformanceReport",
            "products:WeeklySalesReport",
            "products:SalesTrendsReport",
            "products:WeeklySearchTermReport",
            "products:updateBudget",
            // "project:create",
            "home:simple",
            // "credits:view",
            "pricing:view",
            "pricingOptions:view",
            "stores:view",
            // "project:view",
            // "project:review",
            'brandProfile:edit',
            'registrationJourney:view',
            'store:view'
        ],
    },
    4: {
        static: [
            "profile:logout",
            "profile:userProfile",
            //"profile:authorization",
            "profile:addKeywordstracking",
            "profile:spapiAuth",
            "profile:adApiAuth",
            "profile:advertisingAuth",
            "payment:customerPortal",
            "products:add",
            "products:edit",
            "products:view",
            "products:delete",
            "products:updateskus",
            "products:keywordExport",
            "products:productPerformanceReport",
            "products:TargetPerformanceReport",
            "products:StoreOverviewReport",
            "products:StoreOverviewPrimeReport",
            "products:CampaignPerformanceReport",
            "products:WeeklySalesReport",
            "products:SalesTrendsReport",
            "products:WeeklySearchTermReport",
            "products:updateBudget",
            // "project:create",
            "home:simple",
            // "credits:view",
            "pricing:view",
            "addOns:view",
            "stores:view",
            // "project:view",
            // "project:review",
            'brandProfile:edit',
            'registrationJourney:view'
            
        ]
    }
};

export default rules;
